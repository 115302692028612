import React, { useEffect, useState, useRef } from 'react'
import Slick, { Settings } from 'react-slick'
import { classnames } from 'begonya/functions'

import { path } from '@/core/utils'
import { useMobile } from '@/core/hooks'
import { useApp } from '@/core/contexts/app'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'
import IconList, { IconListItem } from '@/components/base/icon-list'
import Anchor from '@/components/base/anchor'

import type { AsgMainSlideshowItem, AsgMainSlideshowProps, Firm } from './types'

import Marquee from 'react-fast-marquee'

import { useWidth } from '@/core/hooks/useWidthResize'

const AsgMainSlideshow: React.FunctionComponent<AsgMainSlideshowProps> = ({
  cards = [],
}) => {
  const app = useApp()
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useMobile(app.headers['user-agent'])
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const posterRefs = useRef<HTMLImageElement[]>([])
  const slideRef = useRef<Slick>(null)
  const mainSliderRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef<number>(null)
  const width = useWidth()

  const [sortedData, setSortedData] = useState<null | AsgMainSlideshowItem[]>(
    null
  )

  // useEffect(() => {
  //   const sortDataByTitle = () => {
  //     const sortedData = cards.sort((a, b) => {
  //       return a.title.localeCompare(b.title)
  //     })
  //     return sortedData
  //   }

  //   const sortedData = sortDataByTitle()
  //   setSortedData(sortedData)
  // }, [cards])

  const settings: Settings = {
    fade: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 16000,
  }

  useEffect(() => {
    const className = 'header-reverse'
    const header = document.getElementsByClassName('asg-header')[0]
    const height = header.clientHeight

    // header.classList.add(className)
    document.body.style.paddingTop = '0px'

    return function cleanup() {
      header.classList.remove(className)
      document.body.style.paddingTop = `${height}px`
    }
  }, [])

  const scrollDown = () => {
    const header = document.getElementsByClassName('asg-main-slide-show')[0]
    const height = header.clientHeight
    window.scrollTo({ top: height, behavior: 'smooth' })
  }

  useEffect(() => {
    if (cards && cards.length) {
      handleVideoInitialize(0)
    }

    return () => {
      if (timeoutRef && timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleSlideBeforeChange = (prevIndex: number, nextIndex: number) => {
    if (videoRefs && videoRefs.current && videoRefs.current.length) {
      const currentVideo = videoRefs.current[nextIndex]
      const prevVideo = videoRefs.current[prevIndex]

      if (prevVideo) {
        prevVideo.currentTime = 0
        prevVideo.pause()
      }

      if (currentVideo) {
        if (currentVideo.readyState > 1) {
          currentVideo.play()
        }

        handleVideoInitialize(nextIndex)
      }
    }

    setActiveIndex(nextIndex)
  }

  const handleVideoInitialize = (index: number) => {
    if (videoRefs.current && videoRefs.current.length) {
      const currentVideo = videoRefs.current[index]
      const slide = cards[index]

      if (currentVideo && (!currentVideo.src || currentVideo.src !== '')) {
        if (isMobile && slide.mobileVideo) {
          currentVideo.src = path.asset(app.environment, slide.mobileVideo.src)
        } else if (slide.video) {
          currentVideo.src = path.asset(app.environment, slide.video.src)
        }
      }
    }
  }

  const handleVideoLoad = () => {
    const currentPoster = posterRefs.current[activeIndex]

    if (currentPoster) {
      currentPoster.classList.add('asg-main-slide-show-video-poster--fade')
    }
  }

  const handleVideoEnded = () => {
    if (slideRef && slideRef.current) {
      slideRef.current.slickNext()
    }
  }

  const MarqueeSlider = (firms: any, index: number) => {
    return (
      <Marquee
        className="marquee-slider"
        direction="right"
        gradient={false}
        speed={10}
        // delay={1}
        pauseOnHover={true}
        pauseOnClick={true}
      >
        {firms?.map((firm: Firm, index2: number) => (
          <div className="item" key={index2}>
            <Anchor {...firm.link}>
              <Image alt={String(firm.title)} {...firm.logo} />
            </Anchor>
          </div>
        ))}
      </Marquee>
    )
  }

  const renderTools = () => {
    return (
      <div className="asg-main-slide-show-tools">
        {cards &&
          cards.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                if (activeIndex === index) {
                  document.getElementById(item.title)?.scrollIntoView({
                    behavior: 'smooth',
                  })
                } else {
                  slideRef?.current?.slickGoTo(index)
                }
              }}
              className={classnames(
                'asg-main-slide-show-dot',
                activeIndex === index && 'asg-main-slide-show-dot--active'
              )}
            >
              {item.title}

              {/* {width > 768 || item.firms.length < 3
              ? activeIndex === index && (
                  <div className="asg-main-slide-show-dot-firms">
                    {item?.firms?.map((firm: Firm, index: number) => (
                      <Anchor key={index} {...firm.link}>
                        <Image alt={String(firm.title)} {...firm.logo} />
                      </Anchor>
                    ))}
                  </div>
                )
              : activeIndex === index && MarqueeSlider(item.firms, item.id)} */}

              {/* {width && width < 768
              ? null
              : item.firms && (width > 768 || item.firms.length < 3)
              ? activeIndex === index && (
                  <div className="asg-main-slide-show-dot-firms">
                    {item?.firms?.map((firm: Firm, index: number) => (
                      <Anchor key={index} {...firm.link}>
                        <Image alt={String(firm.title)} {...firm.logo} />
                      </Anchor>
                    ))}
                  </div>
                )
              : activeIndex === index && MarqueeSlider(item.firms, item.id)} */}
            </button>
          ))}
      </div>
    )
  }

  const renderIcons = () => (
    <>
      {/* <Image
        alt={'vip katalog'}
        src="https://minio.asggrup.com/storage/Root/asggrup/Group 1069(2).svg"
      /> */}
      <button className="down-bottom" onClick={() => scrollDown()}>
        <Icon name="icon-chevron-down" size={'small'} />
      </button>
      <span
        className="title"
        dangerouslySetInnerHTML={{
          __html: app.settings.translations['socialTitle'],
        }}
      />
      <IconList className="footer-social-media-list">
        {app.settings.socials['linkedin'] && (
          <IconListItem
            icon={{
              name: 'icon-linkedin2',
              size: 'large',
              onClick: () =>
                window.open(
                  app.settings.socials['linkedin'] ||
                    'https://www.linkedin.com/asggrup',
                  '_blank'
                ),
              style: { cursor: 'pointer' },
            }}
          />
        )}
        {app.settings.socials['twitter'] && (
          <IconListItem
            icon={{
              name: 'icon-twitter-2',
              onClick: () =>
                window.open(
                  app.settings.socials['twitter'] ||
                    'https://www.twitter.com/asggrup',
                  '_blank'
                ),
              style: { cursor: 'pointer' },
            }}
          />
        )}
        {app.settings.socials['facebook'] && (
          <IconListItem
            icon={{
              name: 'icon-facebook1',
              onClick: () =>
                window.open(
                  app.settings.socials['facebook'] ||
                    'https://www.facebook.com/asggrup',
                  '_blank'
                ),
              style: { cursor: 'pointer' },
            }}
          />
        )}
        {/* {app.settings.socials['instagram'] && (
          <IconListItem
            icon={{
              name: 'icon-instagram',
              onClick: () =>
                window.open(
                  app.settings.socials['instagram'] ||
                    'https://www.instagram.com/asggrup/',
                  '_blank'
                ),
              style: { cursor: 'pointer' },
            }}
          />
        )} */}
      </IconList>
    </>
  )

  const renderFile = (slide: AsgMainSlideshowItem, index: number) => {
    if (slide.video) {
      const video = {
        autoPlay: true,
        controls: false,
        loop: false,
        muted: true,
        playsInline: true,
        preload: 'none',
      }

      return (
        <div className="asg-main-slide-show-video">
          {slide.poster && (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              alt={slide.title}
              className="asg-main-slide-show-video-poster"
              src={path.asset(app.environment, slide.poster.src)}
              ref={(node: HTMLImageElement) =>
                (posterRefs.current[index] = node)
              }
            />
          )}
          {
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              ref={(node) => (videoRefs.current[index] = node)}
              onPlaying={handleVideoLoad}
              onEnded={handleVideoEnded}
              {...video}
            />
          }
        </div>
      )
    }

    if (slide.image) {
      return (
        <Image
          alt={slide.mobileImage?.src}
          width={1920}
          height={1080}
          // srcSmall={slide.mobileImage?.src}
          className="lazyload-container-fullscreen asg-main-slide-show-backdrop"
          {...slide.image}
        />
      )
    }
  }

  return (
    <div ref={mainSliderRef} className="asg-main-slide-show">
      <Slick
        {...settings}
        ref={slideRef}
        beforeChange={handleSlideBeforeChange}
      >
        {cards.map((slide, index) => (
          <React.Fragment key={index}>
            {renderFile(slide, index)}
            <div className="asg-main-slide-show-caption">
              {renderTools()}
              <div className="asg-main-slide-show-caption-footer">
                {renderIcons()}
              </div>
            </div>
          </React.Fragment>
        ))}
      </Slick>
    </div>
  )
}

export default AsgMainSlideshow
